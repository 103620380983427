import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  RowActions,
} from "./widget/RowActions";
import { RowClickEvent } from '../../common/Table';
import { Box } from '@mui/material';
import { ResourceVertical } from '../../common/ResourceVertical';

export const resource = "admin/generic-views/plaid_identities";

const tableColumns = [
  { field: "primary_name", width: "150px", title: "primary name", show: true },
  { field: "names", width: "150px", title: "names", show: true },
  { field: "email", width: "150px", title: "email", show: true },
  { field: "phone_number", width: "150px", title: "phone_number", show: true },
  { field: "address", width: "150px", title: "address", show: true },
  { field: "name", width: "150px", title: "linked bank", show: true },
];

export const UnderwritingPlaidIdentitiesCollection: React.FC<Props> = ({ initialFilters, onRowClick }) => {
  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions record={record} />

  const handleRowClick = ({
    dataItem,
    nativeEvent
  }: RowClickEvent,
  ) => {
    if (onRowClick === undefined) return;

    nativeEvent.stopPropagation();
    nativeEvent.preventDefault();

    const { id } = dataItem;

    if (id === undefined) {
      throw Error('no user_id in row click event');
    }

    onRowClick(id);
  }

  return (
    <Box sx={{ position: "relative" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`Plaid identity: ${initialFilters?.user_id.eq}`}
        </h4>
      </Box>

      <ResourceVertical
        initialFilters={initialFilters}
        resource={resource}
        disableUrlFilterListener={false}
        rowActionBuilder={rowActionBuilder}
        onRowClick={handleRowClick}
        columnModel={tableColumns}
        specialFormat={{ 'names': (names: string[]) => names.join(', ') }}
      />

    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onRowClick?: (underwritingId: string) => void
}