import { AdminRequest, PlacidResponse, PostError } from "./AdminRequest";

export type UpdateCreditCardDataPayload = {
  userId: number
  cardId: number
  apr: number
  limit: number
}

export type UpdateStatementDDPayload = {
  userId: number
  billingCycleId: number
  dueDate: string
}

export type MoveDirection = 'Back' | 'Forward'

export type MoveScheduledTnxDatePayload = {
  userId: number,
  scheduledBTTxnId: number,
  date: string,
}

export type UpdateStatementCyclePayload = {
  userId: number
  lastCycleDay: number
  newAutopayDay?: number
  moveDirection: MoveDirection
}
export class BTActions {
  constructor(private adminRequest: AdminRequest) { };

  async sendBTTnxForce(scheduledBTTxnId: number): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/balance-transfer/force',
          JSON.stringify([{
            scheduledBTTxnId,
            forceSend: true
          }]),
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request sending the bt transaction force failed";
    }
  }

  async btDisburse({
    userId,
    accountId,
    payment,
  }: {
    userId: number,
    accountId: number,
    payment: number,
  }
  ): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/balance-transfer/disburse',
          JSON.stringify({
            userId,
            breakdown: [
              {
                accountId,
                payment,
              }
            ]
          }),
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request disburse failed";
    }
  }

  async moveScheduledDate(
    payload: MoveScheduledTnxDatePayload,
  ): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          "/admin/balance-transfer/move-scheduled-date",
          JSON.stringify(payload),
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request failed";
    }
  }

  _checkPlacidError(placidResponse: PlacidResponse): PostError {
    if (placidResponse.success) {
      return null
    } else {
      return placidResponse.error.message ?? "Something went wrong";
    }
  }
}
