import { Box } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Resource } from "../../common/Resource";
import { RowClickEvent } from "../../common/Table";
import { ColumnModel } from "../../model/ColumnModel";
import { HistoricalPlaidTransactionTableDrawer, HistoricalTablePayload } from "./widget/HistoricalPlaidTransactionTableDrawer";

export const resource = "admin/generic-views/collection_user_bank_accounts";

const tableColumns = [
  { field: "user_id", width: "100px", title: "user id", show: true },
  { field: "current_balance", width: "120px", title: "current balance", show: true },
  { field: "account_name", width: "200px", title: "account name", show: true },
  { field: "available_balance", width: "150px", title: "available balance", show: true },
  { field: "mfi_account_id", width: "200px", title: "mfi account id", show: true },
  { field: "mfi_account_error", width: "200px", title: "mfi account error", show: true },
];

export const UserBankAccountsCollection: React.FC<Props> = ({
  initialFilters,
  onDrawerOpenChange,
}) => {
  const [historicalTablePayload, setHistoricalTablePayload] = useState<HistoricalTablePayload | undefined>(undefined);

  const handleHistoricalTxnClose = () => {
    setHistoricalTablePayload(undefined);
  }

  const handleRowClick = ({ dataItem }: RowClickEvent) => {
    setHistoricalTablePayload({ plaidAccountId: dataItem.plaid_account_id });
  }

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  useEffect(() => {
    onDrawerOpenChange(historicalTablePayload !== undefined);
  }, [historicalTablePayload]);

  return (
    <Box sx={{ position: "relative" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`User bank accounts: ${initialFilters.user_id.eq}`}
        </h4>
      </Box>

      <HistoricalPlaidTransactionTableDrawer
        payload={historicalTablePayload}
        onClose={handleHistoricalTxnClose}
        disableUrlFilerListener={historicalTablePayload === undefined}
      />

      <Resource
        resource={resource}
        initialFilters={initialFilters}
        onRowClick={handleRowClick}
        columnModel={columnModel}
      />

    </Box>
  );
}

type Props = {
  initialFilters: { "user_id": { "eq": string } }
  onDrawerOpenChange: (isOpen: boolean) => void
}