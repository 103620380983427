import { DataProvider, GetListResult } from "react-admin"
import { stringify } from 'query-string'
import { httpClient, headRequest } from "./httpClient"
import { Address } from "../types/address";

function _buildQuery(queryMap: { [index: string]: string | number }, filters: string[], sort: string[]): string {
  let result = "";

  for (const [key, value] of Object.entries(queryMap)) {
    result += `${key}=${value}&`;
  }

  if (filters.length > 0) {
    if (result.length === 0) {
      result += "&";
    }

    result += filters.join('&');
  }

  const sortQuery = sort.length === 0 ? '' : `order=${sort.join(',')}`
  const query = `${result}&${sortQuery}`;

  if (query.length > 1) {
    return `?${query}`;
  }

  return '';
}

const twilioAddressFold = (address: Address): string => {
  const {
    zip4,
    city,
    state_code,
    postal_code,
    country_code,
    street_line_1,
    street_line_2,
  } = address;
  return `${country_code} ${state_code} ${zip4} ${postal_code} ${city} ${street_line_1} ${street_line_2}\n`;
}

const twilioTransform = (jsonAr: { [index: string]: any }[]) => {
  const result = [];
  for (const json of jsonAr) {
    const {
      id,
      user_id,
      ekata_reverse_phone:
      reverse_phone,
      twilio_caller_name: caller_name,
    } = json;

    result.push({
      id,
      names: caller_name?.result?.caller_name?.caller_name,
      user_id,
      phone: reverse_phone.result.phone_number,
      alternate_phones: reverse_phone.result.alternate_phones?.map((p: any) => p?.phone_number ?? "").join(', '),
      current_addresses: reverse_phone.result.current_addresses.map(twilioAddressFold),
      historical_addresses: reverse_phone.result.historical_addresses.map(twilioAddressFold)
    });
  }

  return result;
}


const plaidLinkContentTransform = (linkAction: string, json: { [index: string]: any }) => {
  return json.metadata;
}

const plaidLinkEventsTransform = (jsonAr: { [index: string]: any }[]) => {
  const plaidLinkItems = [];
  for (const json of jsonAr) {
    const {
      id,
      created_at,
      user_id,
      raw_data,
    } = json;

    const link_action = Object.keys(raw_data)[0]
    const metadata = JSON.stringify(raw_data[link_action].metadata);

    plaidLinkItems.push({
      id,
      created_at,
      user_id,
      link_action,
      metadata,
    });
  }

  return plaidLinkItems;
}

const rawAccountsTransformer = (jsonAr: { [index: string]: any }[]) => {
  return jsonAr.map((row) => {
    row["id"] = row["raw_account_id"];
    return row;
  });
}

const historicalPlaidTransactionsTransformer = (jsonAr: { [index: string]: any }[]) => {
  return jsonAr.map((row) => {
    const rawJson = JSON.parse(row["raw_json"]);
    const location = rawJson.location;
    const { store_number, address, country, city, region } = location;
    const geoLocation = [store_number, address, country, city, region, country].filter((chunk) => chunk !== undefined).join(', ');

    row["geo_location"] = geoLocation;
    return row;
  });
}

const seeminglyDiedTokensTransformer = (jsonAr: { [index: string]: any }[]) => {
  return jsonAr.map((row) => {
    row["id"] = row["token_id"];
    return row;
  });
}

const achNumberVerificationTransformer = (jsonAr: { [index: string]: any }[]) => {
  return jsonAr.map((row) => {
    row["id"] = row["number_verification_id"];
    return row;
  });
}


const transformResponse = (json: { [index: string]: any }[], resource: string): any => {
  switch (resource) {
    case "admin/generic-views/twilio":
      return twilioTransform(json);
    case "admin/generic-views/plaid_link_events":
      return plaidLinkEventsTransform(json);
    case "admin/generic-views/collection_historical_plaid_transactions":
      return historicalPlaidTransactionsTransformer(json);
    case "admin/generic-views/raw_accounts":
      return rawAccountsTransformer(json);
    case "admin/generic-views/raw_accounts_depository":
      return rawAccountsTransformer(json);
    case "admin/generic-views/raw_accounts_non_depository":
      return rawAccountsTransformer(json);
    case "admin/generic-views/seemingly_died_tokens":
      return seeminglyDiedTokensTransformer(json);
    case "admin/generic-views/number_verification":
      return achNumberVerificationTransformer(json);
    default:
      return json;
  }
}

export const buildDataProvider = (apiUrl: string): DataProvider => ({
  getList: async (resource, params) => {
    const { page, perPage, } = params.pagination

    const paginationQuery = {
      limit: perPage,
      offset: (page - 1) * perPage,
    }

    const filters: Array<string> = [];
    const sort: Array<string> = [];

    for (const filterKey of Object.keys(params.filter || {})) {
      const valueObj = params.filter[filterKey];
      if (typeof valueObj === "string") {
        filters.push(`${filterKey}=eq.${valueObj}`);
        continue;
      }

      if (filterKey === "order") {
        for (const valueKey of Object.keys(valueObj)) {
          const value = valueObj[valueKey];
          sort.push(`${valueKey}.${value}`);
        }
      } else {
        for (const valueKey of Object.keys(valueObj)) {
          const value = valueObj[valueKey];
          filters.push(`${filterKey}=${valueKey}.${value}`);
        }
      }
    };

    const fullQuery = _buildQuery({ ...paginationQuery }, filters, sort);
    const countQuery = _buildQuery({}, filters, []);

    const url = `${apiUrl}/${resource}${fullQuery}`;
    const countUrl = `${apiUrl}/${resource}${countQuery}`;

    const valueRequest = httpClient(url);
    // const recordCountsRequest = headRequest(countUrl);

    try {
      const [{ json },
        // { headers }
      ] = await Promise
        .all([valueRequest,
          // recordCountsRequest

        ])

      // REMOVE FOR CLIENTS BACK
      // const contentRangeString = headers.get('content-range')?.split('/').shift()?.split('-').pop() as string;
      // const contentRange = contentRangeString === "*" ? null : contentRangeString;
      const total = 9999 //  contentRange == null ? 0 : parseInt(contentRange, 10) + 1;

      const data = transformResponse(json, resource);

      return {
        data,
        total: total,
      }
    } catch (e: any) {
      if (e.status === 403) {
        return {
          data: [],
          total: 0,
        }
      } else {
        throw e;
      }
    }
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/admin/customer/${params.id}/overview`).then(
      ({ json }) => {
        const data = json.result.overview
        data.id = params.id
        return {
          data: data,
        }
      }
    ),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => ({ data: json }))
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: 0,
    }))
  },

  update: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'Post',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }))
  },
});
