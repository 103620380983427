
import { AdminRequest, PlacidResponse, PostError } from "./AdminRequest";

export type AccountTag = "ManualAccount" | "DepositoryAccount"

export type Account = {
  tag: AccountTag
  contents?: number
}

export type PBTnxAdjustPayload = {
  userId: number
  scheduledDate: string
  amount: number
  fromAccount: Account
  transactionId: number
  comment: string
  forceSend?: boolean
  isHiddenInAdminView?: boolean
  isLocked: boolean
}

type PBTnxCancelPayload = {
  userId: number
  transactionId: number
  comment: string
}

type TnxSendManuallyPayload = {
  force: boolean,
  transactionId: number,
  userId: number,
}

export class RepaymentsActions {
  constructor(private adminRequest: AdminRequest) { };

  async pbTnxAdjust(payload: PBTnxAdjustPayload): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/pb-txn/adjust',
          JSON.stringify(payload),
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request pb-tnx/adjust failed";
    }
  }

  async pbTnxCancel(payload: PBTnxCancelPayload): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/pb-txn/cancel',
          JSON.stringify(payload),
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request pb-tnx/cancel failed";
    }
  }

  async cancelMfiOnly(mfiTransactionId: string): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/mfi/transaction/cancel',
          JSON.stringify({ mfiTransactionId }),
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request mfi cancel failed";
    }
  }

  async tnxSendManually(payload: TnxSendManuallyPayload): Promise<PostError> {
    try {
      return this._checkPlacidError(
        await this.adminRequest.post(
          '/admin/pb-txn/send-manually',
          JSON.stringify(payload),
        ),
      );
    } catch (e) {
      if (e instanceof Error) {
        return e.message;
      }

      return "Request send manually failed";
    }
  }

  _checkPlacidError(placidResponse: PlacidResponse): PostError {
    if (placidResponse.success) {
      return null
    } else {
      return placidResponse.error.message ?? "Something went wrong";
    }
  }
}
