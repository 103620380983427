import { Box } from "@mui/material";
import { useCallback, useState } from "react";
import { Button, ExportButton, TopToolbar, useNotify } from "react-admin"
import { AccountsOverview } from "../../../types/accountsOverview";
import { BillingCycle } from "../../../types/billingCycles";
import { selectDepositoryAccounts } from "../ChargesCollection";
import { CreateBtTransactionDialog, CreateBtTransactionDialogPayload } from "./CreateBtTransactionDialog";
import { OneTimePaymentDialog, OneTimePaymentDialogPayload } from "./OneTimePaymentDialog";

export const ChargesActions: React.FC<Props> = ({
  accountsOverview,
  initialFilters,
  billingCycles,
}) => {
  const notify = useNotify();

  const [
    oneTimePaymentPayload,
    setOneTimePaymentPayload,
  ] = useState<OneTimePaymentDialogPayload>(null);

  const [
    createBtTransactionPayload,
    setCreateBtTransactionPayload,
  ] = useState<CreateBtTransactionDialogPayload>(null);

  const handleCreateClick: React.MouseEventHandler<HTMLButtonElement> = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (accountsOverview === undefined) {
      notify('No account overview load');
      return;
    }

    const userId = initialFilters.user_id.eq;

    if (billingCycles === null || billingCycles === undefined) {
      notify("Billing cycles is null");
      return;
    }

    setCreateBtTransactionPayload({
      userId,
      billingCycles,
      depositoryAccounts: selectDepositoryAccounts(accountsOverview),
      // isLocked: isLocked,
    });
  }, [accountsOverview, initialFilters]);

  const handleOneTimePayment = useCallback(() => {
    if (accountsOverview === undefined) {
      notify("accountsOverview not loaded");
      return;
    }

    const userId = initialFilters.user_id.eq;

    setOneTimePaymentPayload({
      accountsOverview,
      userId,
    });
  }, [accountsOverview?.currentSingleBillCycle?.singleBillCycleId])


  return (
    <Box>
      <OneTimePaymentDialog
        payload={oneTimePaymentPayload}
        hideDialog={() => setOneTimePaymentPayload(null)} />

      <CreateBtTransactionDialog
        payload={createBtTransactionPayload}
        hideDialog={() => setCreateBtTransactionPayload(null)} />

      <Button label="create" onClick={handleCreateClick} />
      <Button label="charge now" onClick={handleOneTimePayment} />
    </Box>
  )
}

type Props = {
  accountsOverview: AccountsOverview | undefined
  initialFilters: { "user_id": { "eq": string } }
  billingCycles: BillingCycle[] | undefined
}
