import React, { useMemo, } from 'react';
import { Box } from '@mui/material';
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { DateInput, FilterButton, SelectInput, TextInput } from 'react-admin';
import { DollarInput } from '../../common/DollarInput';
import { RowActions } from './widget/RowActions';
import { RowClickEvent } from '../../common/Table';

export const resource = "admin/generic-views/repayments";

const tableColumns = [
  { field: "id", width: "150px", title: "id", show: true },
  { field: "created_at", width: "150px", title: "created_at", show: true, format: "yyyy-MM-dd" },
  { field: "scheduled_date", width: "150px", title: "scheduled_date", show: true, format: "yyyy-MM-dd" },
  { field: "amount", width: "150px", title: "amount", show: true, isCents: true },
  { field: "current_status", width: "150px", title: "current_status", show: true },
  { field: "status_description", width: "200px", title: "status_description", show: true },
  { field: "user_id", width: "150px", title: "user_id", show: true },
  { field: "name", width: "150px", title: "name", show: true },
  { field: "due_date", width: "150px", title: "due_date", show: true, format: "yyyy-MM-dd" },
  { field: "bank_account", width: "150px", title: "bank_account", show: true },
  { field: "manual_account", width: "150px", title: "manual_account", show: true },
  { field: "stripe_payment_token", width: "150px", title: "stripe_payment_token", show: true },
  { field: "token_expired", width: "150px", title: "token_expired", show: true },
  { field: "bank_account_balance", width: "150px", title: "bank_account_balance", show: true, isCents: true },
  { field: "last_seen", width: "150px", title: "last_seen", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "bank_name", width: "150px", title: "bank_name", show: true },
  { field: "bank_account_name", width: "150px", title: "bank_account_name", show: true },

  { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="user_id" source="user_id.eq" alwaysOn />,

  <TextInput label="last_name" source="last_name.like" />,

  <DateInput label="created_at gt" source="created_at.gt" />,
  <DateInput label="created_at lt" source="created_at.lt" />,

  <DateInput label="scheduled_date gt" source="scheduled_date.gt" />,
  <DateInput label="scheduled_date lt" source="scheduled_date.lt" />,

  <SelectInput source="current_status" choices={[
    { id: 'Failure', name: 'Failure' },
    { id: 'Pending', name: 'Pending' },
    { id: 'Canceled', name: 'Canceled' },
    { id: 'Sent', name: 'Sent' },
    { id: 'Scheduled', name: 'Scheduled' },
  ]} />,

  <DateInput label="due_date gt" source="due_date.gt" />,
  <DateInput label="due_date lt" source="due_date.lt" />,

  <TextInput label="bank_account" source="bank_account.eq" />,
  <TextInput label="manual_account" source="manual_account.eq" />,
  <TextInput label="stripe_payment_token" source="stripe_payment_token.eq" />,
  <TextInput label="bank_name" source="bank_name.eq" />,
  <TextInput label="bank_account_name" source="bank_account_name.eq" />,

  <SelectInput source="token_expired" choices={[
    { id: 'true', name: 'Expired' },
    { id: 'false', name: 'Not expired' },
  ]} />,

  <DollarInput label="bank_account_balance gt (dollar)" source="bank_account_balance.gt" />,
  <DollarInput label="bank_account_balance lt (dollar)" source="bank_account_balance.lt" />,

  <DateInput label="last_seen gt" source="last_seen.gt" />,
  <DateInput label="last_seen lt" source="last_seen.lt" />,
];

export const RepaymentsCollection: React.FC<Props> = (props) => {
  const {
    initialFilters,
    rowPerPageOptions = [25, 50, 100],
    disableUrlFilterListener = false,
    onRowClick = (_) => { },
    title = "SB payments"
  } = props;

  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions record={record} />

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, []);

  const handleRowClick = ({
    dataItem,
    nativeEvent
  }: RowClickEvent,
  ) => {
    nativeEvent.stopPropagation();
    nativeEvent.preventDefault();

    const { user_id: userId } = dataItem;

    if (userId !== undefined) {
      onRowClick(userId);
    } else {
      throw Error('no user_id in row click event');
    }
  }

  return (
    <Box sx={{ position: "relative", paddingTop: "60px" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px"
      }}>
        <h4>
          {title}
        </h4>
      </Box>

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        disableUrlFilterListener={disableUrlFilterListener}
        columnModel={columnModel}
        rowPerPageOptions={rowPerPageOptions}
        filters={filters}
        rowActionBuilder={rowActionBuilder}
        onRowClick={handleRowClick}
        tableActions={
          <>
            <FilterButton {...props} />
          </>
        }
      />

    </Box>
  )
}

type Props = {
  title?: string
  initialFilters?: { [index: string]: any }
  onRowClick?: (userId: string) => void
  rowPerPageOptions?: number[]
  disableUrlFilterListener?: boolean
}