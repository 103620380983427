
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Alert, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { DepositoryAccount } from '../../../types/accountsOverview';
import { PostError } from '../../../network/AdminRequest';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';

export type UpdateBCDDDialogPayload = {
  userId: string;
  billingCycleId: number;
  dueDate: Date;
} | null;

export const UpdateBCDDDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { debtActions } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [dueDate, setDueDate] = useState<string>("");
  const [error, setError] = useState<PostError>(null);

  // init
  useEffect(() => {
    if (payload !== null) {
      setDueDate(payload.dueDate.toString());
    }
    setError(null);
  }, [payload]);

  const handleDueDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setDueDate(value);
  }

  const handleSendClick = async () => {
    if (payload === null) return;

    const { userId, billingCycleId } = payload;

    const error = await debtActions.updateBillingCycleDD({
      userId: parseInt(userId),
      billingCycleId,
      dueDate: dueDate,
    });

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("updated");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== null}
      onClose={hideDialog}
      title="Update transaction"
      actionTitle='Send'
      onSend={handleSendClick}>

      <TextField
        fullWidth
        type="date"
        label="Transaction date"
        value={dueDate}
        onChange={handleDueDateChange}
        variant="outlined"
      />

      {error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload: UpdateBCDDDialogPayload;
  hideDialog: () => void;
}