import React, { useCallback, useEffect, useMemo } from 'react';
import {
  DateInput,
  FilterButton,
  NumberInput,
  SelectInput,
  TextInput,
} from "react-admin"
import {
  RowActions,
} from "./widget/RowActions";
import { RowClickEvent } from "../../common/Table";
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { DollarInput } from '../../common/DollarInput';

export const resource = "admin/generic-views/collection_overview";

const tableColumns = [
  { field: "user_id", width: "120px", title: "user id", show: true },
  { field: "f_name", width: "120px", title: "f name", show: true },
  { field: "l_name", width: "120px", title: "l name", show: true },
  { field: "status", width: "180px", title: "status", show: true },
  { field: "last_seen", width: "220px", title: "last seen", show: true, format: "yyyy-MM-dd" },
  { field: "l_scored", width: "150px", title: "l score", show: true },
  { field: "f_fico", width: "100px", title: "f fico", show: true },
  { field: "c_fico", width: "100px", title: "c fico", show: true },
  { field: "st_due_date", width: "100px", title: "st due date", show: false },
  { field: "charge_day", width: "120px", title: "charge day", show: true },
  { field: "trn_status", width: "100px", title: "trn status", show: true },
  { field: "dpd", width: "100px", title: "dpd", show: false },
  { field: "to_pay", width: "100px", title: "to pay", show: false, isCents: true },
  { field: "pmt_due", width: "100px", title: "pmt due", show: false },
  { field: "trn_sum", width: "100px", title: "tnx sum", show: true, isCents: true },
  { field: "available", width: "150px", title: "available", show: true, isCents: true },
  { field: "balance", width: "100px", title: "balance", show: true, isCents: true },
  { field: "max_other", width: "120px", title: "max other", show: false, isCents: true },
  { field: "autopay", width: "100px", title: "autopay", show: true },
  { field: "pmt_type", width: "200px", title: "pmt type", show: false },
  { field: "from_type", width: "200px", title: "from type", show: false },
  { field: "from_account_name", width: "200px", title: "from account name", show: false },
  { field: "mfi_id", width: "200px", title: "mfi id", show: false },
  { field: "force_send", width: "200px", title: "force send", show: false },
  { field: "mfi_trx_error", width: "200px", title: "mfi trx error", show: false },
  { field: "expired_at", width: "200px", title: "expired at", show: true, format: "yyyy-MM-dd" },
  { field: "collection", width: "200px", title: "collection", show: false },
  { field: "max_other_tkn_exp", width: "200px", title: "max_other_tkn_exp", show: false, format: "yyyy-MM-dd" },

  { field: "actions", width: "140px", title: "actions", show: true },
]

const filters = [
  <TextInput label="user id" source="user_id.eq" alwaysOn />,
  <TextInput label="first name" source="f_name.like" />,
  <TextInput label="last name" source="l_name.like" />,

  <SelectInput source="status" choices={[
    { id: 'Active', name: 'Active' },
    { id: 'Deactivated', name: 'Deactivated' },
    { id: 'Pending', name: 'Pending' },
    { id: 'Paused ResigningAgreement', name: 'Paused ResigningAgreement' },
  ]} />,
  <DateInput label="last seen gt" source="last_seen.gt" />,
  <DateInput label="last seen lt" source="last_seen.lt" />,

  <DateInput label="l scored gt" source="l_scored.gt" />,
  <DateInput label="l scored lt" source="l_scored.lt" />,

  <NumberInput label="f_fico gt" source="f_fico.gt" />,
  <NumberInput label="f_fico lt" source="f_fico.lt" />,

  <NumberInput label="c_fico gt" source="c_fico.gt" />,
  <NumberInput label="c_fico lt" source="c_fico.lt" />,

  <DateInput label="st_due_date gt" source="st_due_date.gt" />,
  <DateInput label="st_due_date lt" source="st_due_date.lt" />,

  <DateInput label="charge_day gt" source="charge_day.gt" />,
  <DateInput label="charge_day lt" source="charge_day.lt" />,

  <SelectInput source="trn_status" multiline choices={[
    { id: 'Failure', name: 'Failure' },
    { id: 'Pending', name: 'Pending' },
    { id: "Sent", name: "Sent" },
    { id: "Canceled", name: "Canceled" },
    { id: "Scheduled", name: "Scheduled" },
  ]} />,

  <DateInput label="dpd gt" source="dpd.gt" />,
  <DateInput label="dpd lt" source="dpd.lt" />,

  <DollarInput label="to_pay gt (Dollar)" source="to_pay.gt" />,
  <DollarInput label="to_pay lt (Dollar)" source="to_pay.lt" />,

  <NumberInput label="pmt_due" source="to_pay.eq" />,

  <DollarInput label="trn_sum gt (Dollar)" source="trn_sum.gt" />,
  <DollarInput label="trn_sum lt (Dollar)" source="trn_sum.lt" />,

  <DollarInput label="available gt (Dollar)" source="available.gt" />,
  <DollarInput label="available lt (Dollar)" source="available.lt" />,

  <DollarInput label="balance gt (Dollar)" source="balance.gt" />,
  <DollarInput label="balance lt (Dollar)" source="balance.lt" />,

  <DollarInput label="max_other gt (Dollar)" source="max_other.gt" />,
  <DollarInput label="max_other lt (Dollar)" source="max_other.lt" />,

  <SelectInput source="autopay" choices={[
    { id: 'true', name: 'Enabled' },
    { id: 'false', name: 'Disabled' },
  ]} />,

  <SelectInput source="pmt_type" choices={[
    { id: 'UserManualPayment', name: 'UserManualPayment' },
    { id: 'StripePayment', name: 'StripePayment' },
    { id: 'Depository', name: 'Depository' },
  ]} />,

  <SelectInput source="from_type" choices={[
    { id: 'Regular', name: 'Regular' },
    { id: 'OneTime', name: 'OneTime' },
  ]} />,

  // like не работает?
  // <TextInput label="from account name" source="from_account_name.like" />,

  <TextInput label="mfi id" source="mfi_id.eq" />,

  <SelectInput source="force_send" choices={[
    { id: 'true', name: 'Enabled' },
    { id: 'false', name: 'Disabled' },
  ]} />,

  <SelectInput source="collection" choices={[
    { id: 'true', name: 'Enabled' },
    { id: 'false', name: 'Disabled' },
  ]} />,

  <DateInput label="max_other_tkn_exp gt" source="max_other_tkn_exp.gt" />,
  <DateInput label="max_other_tkn_exp lt" source="max_other_tkn_exp.lt" />,
];


export const DebtCollection: React.FC<Props> = (props) => {
  const {
    onSet,
    disableUrlFilterListener,
  } = props;

  const rowActionBuilder = (record: { [index: string]: any }) => <RowActions record={record} />

  const handleRowClick = ({
    dataItem,
    nativeEvent
  }: RowClickEvent,
  ) => {
    if (onSet === null || onSet === undefined) return;

    nativeEvent.stopPropagation();
    nativeEvent.preventDefault();

    const { user_id } = dataItem;

    if (user_id === undefined) {
      throw Error('no user_id in row click event');
    }

    if (user_id !== undefined) {
      onSet(user_id);
    }
  }

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  return (
    <Resource
      resource={resource}
      filters={filters}
      disableUrlFilterListener={disableUrlFilterListener}
      rowActionBuilder={rowActionBuilder}
      onRowClick={handleRowClick}
      columnModel={columnModel}
      tableActions={
        <>
          <FilterButton {...props} />
        </>
      }
    />
  )
}

type Props = {
  onSet?: (id: string | null | undefined) => void,
  initialFilters?: { [index: string]: string }
  disableUrlFilterListener: boolean
}